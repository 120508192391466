






















import defaultComponent from "../utils/defaultComponent";

export default defaultComponent.apply({
  data() {
    return {
      height: "",
    };
  },
  methods: {
    recalculateHeight() {
      if (
        !this.options ||
        !this.options.attrs ||
        !this.options.attrs["fixed-header"]
      ) {
        return;
      }
      if (!this.$refs.simpleTable) {
        return;
      }
      this.$nextTick(() => {
        const el = this.$refs.simpleTable.$el;
        const contentHeight = el.querySelector(
          ".v-data-table__wrapper"
        ).scrollHeight;
        let newHeight = window.innerHeight - el.getBoundingClientRect().top;
        if (newHeight >= contentHeight) {
          newHeight = undefined;
        } else if (newHeight < 200) {
          newHeight = undefined;
        }
        this.height = newHeight;
      });
    },
  },
  mounted() {
    this.recalculateHeight();
    window.addEventListener("resize", () => {
      this.recalculateHeight();
    });
  },
  extraOptions: {
    defaultClass: "simpleTable",
  },
});
